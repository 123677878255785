import React, { useState } from 'react';

function Hint({ helperText }) {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  return (
    <>
      <button type="button" onClick={toggleModal} aria-label="Show hint" className="hint-button">
        ?
      </button>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={toggleModal}>&times;</span>
            <p>{helperText}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default Hint;
