import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Input from './form/input/Input';
import Textarea from './form/input/Textarea';
import Date from './form/input/Date';
import Dropdown from './form/input/Dropdown';
import RadioButtonList from './form/input/RadioButtonList';
import CheckboxList from './form/input/CheckboxList';
import Number from './form/input/Number';
import Email from './form/input/Email';
import Phone from './form/input/Phone';
import Document from './form/input/Document';
import Password from './form/input/Password';
import Don from './form/input/Don';

const DragDropList = ({ inputConfigs, setInputConfigs, selectInputForEditing, handleDeleteField, showEnglishInputs }) => {
    const inputTypeConfig = {
        text: (config, disabled) => (
            <Input
                label={showEnglishInputs && config.labelEn ? config.labelEn : config.label}
                id={config.id}
                value={showEnglishInputs && config.valueEn ? config.valueEn : config.value}
                name="value"
                placeholder={showEnglishInputs && config.placeholderEn ? config.placeholderEn : config.placeholder}
                required={config.required}
            />
        ),
        textarea: (config, disabled) => (
            <Textarea
                label={showEnglishInputs && config.labelEn ? config.labelEn : config.label}
                id={config.id}
                value={showEnglishInputs && config.valueEn ? config.valueEn : config.value}
                name="value"
                placeholder={showEnglishInputs && config.placeholderEn ? config.placeholderEn : config.placeholder}
                required={config.required}
            />
        ),
        date: (config, disabled) => (
            <Date
                label={showEnglishInputs && config.labelEn ? config.labelEn : config.label}
                id={config.id}
                value={showEnglishInputs && config.valueEn ? config.valueEn : config.value}
                name="value"
                min={config.minDate}
                max={config.maxDate}
                required={config.required}
            />
        ),
        dropdown: (config) => (
            <Dropdown
                label={showEnglishInputs && config.labelEn ? config.labelEn : config.label}
                id={config.id}
                value={showEnglishInputs && config.valueEn ? config.valueEn : config.value}
                name="value"
                options={showEnglishInputs ? config.options.map(option => option.valueEn) : config.options.map(option => option.valueFr)}
                required={config.required}
            />
        ),
        radio: (config) => (
            <RadioButtonList
                label={showEnglishInputs && config.labelEn ? config.labelEn : config.label}
                id={config.id}
                value={showEnglishInputs && config.valueEn ? config.valueEn : config.value}
                options={showEnglishInputs ? config.options.map(option => option.valueEn) : config.options.map(option => option.valueFr)}
                required={config.required}
            />
        ),
        checkbox: (config) => (
            <CheckboxList
                label={showEnglishInputs && config.labelEn ? config.labelEn : config.label}
                id={config.id}
                selected={Array.isArray(config.selected) ? config.selected : []}
                onChange={(e) => {}}
                options={showEnglishInputs ? config.options.map(option => option.valueEn) : config.options.map(option => option.valueFr)}
                required={config.required}
            />
        ),
        number: (config, disabled) => (
            <Number
                label={showEnglishInputs && config.labelEn ? config.labelEn : config.label}
                id={config.id}
                value={showEnglishInputs && config.valueEn ? config.valueEn : config.value}
                name="value"
                placeholder={showEnglishInputs && config.placeholderEn ? config.placeholderEn : config.placeholder}
                required={config.required}
            />
        ),
        email: (config, disabled) => (
            <Email
                label={showEnglishInputs && config.labelEn ? config.labelEn : config.label}
                id={config.id}
                value={showEnglishInputs && config.valueEn ? config.valueEn : config.value}
                name="value"
                placeholder={showEnglishInputs && config.placeholderEn ? config.placeholderEn : config.placeholder}
                required={config.required}
            />
        ),

        phone: (config, disabled) => (
            <Phone
                label={showEnglishInputs && config.labelEn ? config.labelEn : config.label}
                id={config.id}
                value={showEnglishInputs && config.valueEn ? config.valueEn : config.value}
                name="value"
                placeholder={showEnglishInputs && config.placeholderEn ? config.placeholderEn : config.placeholder}
                required={config.required}
            />
        ),
        document: (config, disabled) => (
            <Document
                label={showEnglishInputs && config.labelEn ? config.labelEn : config.label}
                id={config.id}
                value={showEnglishInputs && config.valueEn ? config.valueEn : config.value}
                name="value"
                required={config.required}
            />
        ),
        password: (config, disabled) => (
            <Password
                label={showEnglishInputs && config.labelEn ? config.labelEn : config.label}
                id={config.id}
                value={showEnglishInputs && config.valueEn ? config.valueEn : config.value}
                name="value"
                placeholder={showEnglishInputs && config.placeholderEn ? config.placeholderEn : config.placeholder}
                required={config.required}
            />
        ),

        don: (config) => (
            <Don
                title={showEnglishInputs && config.titleEn ? config.titleEn : config.title}
                label={showEnglishInputs && config.labelEn ? config.labelEn : config.label}
                id={config.id}
                value={showEnglishInputs && config.valueEn ? config.valueEn : config.value}
                options={config.options.map(option => option.value)}
                required={true}
                showCheckbox={config.showCheckbox}
                showPeriodique={config.showPeriodique}
            />
        ),
              
    };   

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }

        const items = Array.from(inputConfigs);
        const [reorderedItem] = items.splice(source.index, 1);
        items.splice(destination.index, 0, reorderedItem);

        setInputConfigs(items.map((item, index) => ({
            ...item,
            fieldNumber: index + 1,
        })));
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="fieldsList">
                {(provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef} style={{ listStyleType: 'none', padding: 0 }}>
                        {inputConfigs.map((config, index) => (
                            <Draggable key={config.id} draggableId={config.id} index={index}>
                                {(provided) => (
                                    <li
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                            marginBottom: '10px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            backgroundColor: '#f9f9f9',
                                            padding: '10px',
                                            ...provided.draggableProps.style
                                        }}
                                    >
                                        <div onClick={() => selectInputForEditing(index)}>
                                            <strong>Champ #{index + 1}</strong>
                                            {config.helper && config.helper.trim() !== "" && <span>🛈</span>}
                                            {inputTypeConfig[config.type] ? inputTypeConfig[config.type](config) : null}
                                        </div>
                                        <button onClick={() => handleDeleteField(index)} style={{ marginTop: '5px' }}>
                                            Delete
                                        </button>
                                    </li>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DragDropList;
