import './App.css';
import axios from 'axios';
import React, { useState} from 'react';
import { isValidPhoneNumber } from 'libphonenumber-js';
import DynamicInput from './Components/DynamicInput';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Forms from './pages/Forms';
import EditForm from './pages/EditForm';
import Formulaire from './pages/Formulaire';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Forms />} />
          <Route path="/forms" element={<Forms />} />
          <Route path="/edit-form" element={<EditForm />} />
          <Route path="/formulaire" element={<Formulaire />} />
        </Routes>
      </BrowserRouter>
    </>

  );
}

export default App;
