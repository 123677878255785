import React from 'react';

const InputTypeSelectionModal = ({ showModal, setShowModal, onTypeSelect }) => {
  if (!showModal) return null;

  return (
    <div style={{
      position: 'absolute', top: '20%', left: '30%', background: 'white',
      padding: '20px', border: '1px solid #ccc', zIndex: 100
    }}>
      <div>
        <button onClick={() => onTypeSelect('text')}>Text Input</button>
        <button onClick={() => onTypeSelect('textarea')}>Textarea</button>
        <button onClick={() => onTypeSelect('date')}>Date</button>
        <button onClick={() => onTypeSelect('dropdown')}>Dropdown</button>
        <button onClick={() => onTypeSelect('radio')}>Radio Button List</button>
        <button onClick={() => onTypeSelect('password')}>Password</button>
      </div>
      
      <div>
        <button onClick={() => onTypeSelect('checkbox')}>Check Box List</button>
        <button onClick={() => onTypeSelect('number')}>Number Input</button>
        <button onClick={() => onTypeSelect('email')}>Email Input</button>
        <button onClick={() => onTypeSelect('phone')}>Phone Input</button>
        <button onClick={() => onTypeSelect('document')}>File Input</button>
        <button onClick={() => onTypeSelect('don')}>Don</button>
      </div>
      
      <button onClick={() => setShowModal(false)} style={{ marginTop: '20px', display: 'block' }}>Close</button>
    </div>
  );
};

export default InputTypeSelectionModal;
