import Hint from "../../Hint"
import '../../Hint.css'
import React, { useState } from 'react';

function Don({label, value, options, onChange, id, helper, layout, required, title, showCheckbox, showPeriodique, minValue, maxValue}) {
    const layoutClass = layout === 2 ? 'half-width' : 'full-width';
    const [fraisChecked, setFraisChecked] = useState(false);
    const [periodiqueChecked, setPeriodiqueChecked] = useState(false);

    const handleRadioChange = (e) => {
        onChange(e);
    };

    const handleNumberInputChange = (e) => {
        const syntheticEvent = {
            target: {
                value: e.target.value,
                name: id
            }
        };
        onChange(syntheticEvent);
    };

    const handleFraisChange = (e) => {
        setFraisChecked(e.target.checked);
        onChange({target: {name: 'frais', value: e.target.checked}});
    };

    const handlePeriodiqueChange = (e) => {
        setPeriodiqueChecked(e.target.checked);
        onChange({target: {name: 'periodique', value: e.target.checked}});
    };
    return (
        <div className={layoutClass}>
            <h3>{title}</h3>
            <div><label>{label}{required && <span style={{color: 'red'}}> *</span>}:</label></div>
            {options.map((option) => (
                <label key={option}>
                    <input type="radio" id={option} name={id} value={option} checked={value === option} onChange={handleRadioChange} />
                    <label>{option}</label>
                </label>
            ))}
            <div>
                <input 
                    type="number" 
                    value={value} 
                    onChange={handleNumberInputChange}
                    min={minValue}
                    max={maxValue}
                />
            </div>
            {showCheckbox && (
                <div>
                    <input type="checkbox" checked={fraisChecked} onChange={handleFraisChange}/>
                    <label>Couvrir les frais de transaction</label>
                </div>
            )}

            {showPeriodique && (
                <div>
                    <input type="checkbox" checked={periodiqueChecked} onChange={handlePeriodiqueChange} />
                    <label>Don périodique (Mensuel)</label>
                </div>
            )}
        </div>
      );
}
export default Don