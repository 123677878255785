import '../App.css';
import axios from 'axios';
import React, { useState} from 'react';
import { isValidPhoneNumber } from 'libphonenumber-js';
import DynamicInput from '../Components/DynamicInput';

function Formulaire() {
  const [formData, setFormData] = useState({
    inputs: [
      { id: 'nom', type: 'text', value: '', placeholder: 'Nom', label: 'Nom', required: true, helper: null, layout: 2 },
      { id: 'prenom', type: 'text', value: '', placeholder: 'Prénom', label: 'Prénom', required: true, helper: null, layout: 2  },
      { id: 'email', type: 'email', value: '', placeholder: 'Email', label: 'Email', required: true, helper: "Votre email" , layout: 1},
      { id: 'phone', type: 'tel', value: '', label: 'Telephone',required: false, helper: null , layout: 1},
      { id: 'date', type: 'date', value: '', label: 'Date de naissance', min: '1924-01-01', max: '2014-01-01', required: true, helper: null , layout: 2},
      { id: 'fruits', type: 'dropdown', value: 'Pomme', options: ["Pomme", "Orange", "Fraise", "Raisin", "Citron"], label: "Fruit", required: false, helper: null, layout: 2 },
      { id: 'animals', type: 'radio-button-list', value: '', options: ["Chien", "Chat"], label: "Chien ou Chat", required: false, helper: null, layout: 1 },
      { id: 'interets', type: 'checkbox-list', value: [], options: ["Soccer", "Guitare", "Livres"], label: "Interets", required: false, helper: "Une option ou plus", layout: 1 },
      { id: 'document', type: 'document', value: '', label: "Document", required: false, helper: null, layout: 1 },
      { id: 'message', type: 'textarea', value: '', label: "Message", placeholder: "Message", required: false, helper: null, layout: 1 },
      { id: 'dons', type: 'don', value: '', options: ["5", "10", "15", "20"], label: "Don", title: "CHOISISSEZ UN MONTANT", required: true, helper: null, showCheckbox: true, showPeriodique: true, minValue: 5, maxValue: 100, frais: false, periodique: false},
    ],
  });
  
  const [phoneError, setPhoneError] = useState(false);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;

    if (name === 'frais' || name === 'periodique') {
      setFormData(prevFormData => ({
          inputs: prevFormData.inputs.map(field =>
              field.id === 'dons' ? { ...field, [name]: value } : field
          )
      }));
  } else if (type === 'checkbox') {
      setFormData(prevFormData => ({
          inputs: prevFormData.inputs.map(field =>
              field.id === name ? {
                  ...field,
                  value: e.target.checked ? [...field.value, value] : field.value.filter(item => item !== value)
              } : field
          )
      }));
  } else if (type === 'file') {
      setFormData(prevFormData => ({
        inputs: prevFormData.inputs.map(field =>
          field.id === name ? { ...field, value: "Binary" } : field
        )
      }));
    } else {
      setFormData(prevFormData => ({
        inputs: prevFormData.inputs.map(field =>
          field.id === name ? { ...field, value } : field
        )
      }));
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFieldsFilled = formData.inputs.every(field => !field.required || (field.required && field.value.trim() !== ''));
    if (!requiredFieldsFilled) {
      alert("Veuillez remplir tous les champs obligatoires.");
      return;
    }

    if (phoneError) {
      alert("Veuillez enter un numero de telephone valide.");
      return;
    }
  
    const submissionData = formData.inputs.reduce((acc, field) => {
      acc[field.id] = field.value;
      if (field.id === 'dons') {
        acc['frais'] = field.frais;
        acc['periodique'] = field.periodique;
      }
      return acc;
    }, {});
  
    try {
      const response = await axios.post('https://jsonplaceholder.typicode.com/posts', submissionData);
      console.log('Nice', response.data);
    } catch (error) {
      console.error('Error', error);
    }
  };
  

  return (
    <form className='formulaire' onSubmit={handleSubmit}>
      <h1>Formulaire</h1>

      <div className="form-inputs-container">
        {formData.inputs.map((fields) => (
        <DynamicInput
          key={fields.id}
          label={fields.label}
          required={fields.required}
          type={fields.type}
          id={fields.id}
          value={fields.value}
          name={fields.id}
          placeholder={fields.placeholder}
          onChange={handleChange}
          options={fields.options}
          min={fields.min}
          max={fields.max}
          helper={fields.helper}
          layout={fields.layout}
          title={fields.title}
          showCheckbox={fields.showCheckbox}
          showPeriodique={fields.showPeriodique}
          minValue={fields.minValue}
          maxValue={fields.maxValue}
        />
        ))}
      </div>

      <input type="submit" value="Continuer"/>
    </form>
  );
}

export default Formulaire;
