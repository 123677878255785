import React, {useState} from 'react';
import { Link } from 'react-router-dom';

function Forms() {
  const [forms, setForms] = useState(() => {
    const savedForms = localStorage.getItem('forms');
    return savedForms ? JSON.parse(savedForms) : [];
  });

  const addForm = () => {
    const newForm = {
      id: new Date().getTime(),
      name: `Form ${new Date().getTime()}`
    };
    const updatedForms = [...forms, newForm];
    console.log('allo')
    setForms(updatedForms);
    localStorage.setItem('forms', JSON.stringify(updatedForms));
  };

  const deleteForm = (formId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this form?");
    if (isConfirmed) {
      const updatedForms = forms.filter(form => form.id !== formId);
      setForms(updatedForms);
      localStorage.setItem('forms', JSON.stringify(updatedForms));
    }
  };
  
  return (
    <div>
      <h1>Mes formulaires</h1>
      <button onClick={addForm}>Créer un formulaire</button>
      <div>
        {forms.map(form => (
          <div key={form.id}>
            {form.name}
            <Link to={`/edit-form?formId=${form.id}`}>
              <button>Modifier</button>
            </Link>
            <button onClick={() => deleteForm(form.id)}>Supprimer</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Forms;
