import React, { useState, useEffect } from 'react';
import ConfigWindow from '../Components/ConfigWindow';
import { useLocation } from 'react-router-dom';
import FormNameEdit from '../Components/FormNameEdit';
import InputTypeSelectionModal from '../Components/InputTypeSelectionModal';
import DragDropList from '../Components/DragDropList';

function EditForm() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const formId = queryParams.get('formId');

    const [showModal, setShowModal] = useState(false);
    const [inputConfigs, setInputConfigs] = useState(() => {
        const savedFields = localStorage.getItem(`formFields-${formId}`);
        return savedFields ? JSON.parse(savedFields) : [];
    });
    const [selectedInputIndex, setSelectedInputIndex] = useState(null);
    const [showConfigWindow, setShowConfigWindow] = useState(false);

    const [formName, setFormName] = useState(() => {
        const savedFormName = localStorage.getItem(`formName-${formId}`);
        return savedFormName || `Form ${formId}`;
    });
    const [showEnglishInputs, setShowEnglishInputs] = useState(false);
    
    useEffect(() => {
        localStorage.setItem(`formFields-${formId}`, JSON.stringify(inputConfigs));
    }, [inputConfigs, formId]);

    const handleAddFieldClick = () => {
        setShowModal(true);
    };

    const handleTypeSelection = (type) => {
        const newFieldNumber = inputConfigs.length > 0 ? inputConfigs[inputConfigs.length - 1].fieldNumber + 1 : 1;
        const newInputConfig = {
            id: `field-${newFieldNumber}`,
            type: type,
            placeholder: '',
            placeholderEn: '',
            label: '',
            labelEn: '',
            fieldNumber: newFieldNumber,
            required: false,
            defaultValue: '',
            defaultValueEn: '',
            minDate: type === 'date' ? '' : undefined,
            maxDate: type === 'date' ? '' : undefined,
            options: type === 'dropdown' || type === 'radio' || type === 'checkbox' || type === 'don' ? [{ valueFr: 'Option 1', valueEn: 'Option 1' }, { valueFr: 'Option 2', valueEn: 'Option 2' }] : undefined,
            half: 'Non',
            className: '',
            helper: '',
            helperEn: '',
            title: '',
            titleEn: '',
            minValue: '',
            maxValue: '',
            showCheckbox: false,
            showPeriodique: false,
        };
        setInputConfigs([...inputConfigs, newInputConfig]);
        setShowModal(false);
        setSelectedInputIndex(inputConfigs.length);
        setShowConfigWindow(true);
    };      

    const selectInputForEditing = (index) => {
        setSelectedInputIndex(index);
        setShowConfigWindow(true);
    };

    const onSave = () => {
        console.log(inputConfigs);

        setShowConfigWindow(false);
    };

    const handleDeleteField = (indexToDelete) => {
        const updatedConfigs = inputConfigs
            .filter((_, index) => index !== indexToDelete)
            .map((config, newIndex) => ({
                ...config,
                fieldNumber: newIndex + 1,
                id: `field-${newIndex + 1}`,
            }));
    
        setInputConfigs(updatedConfigs);
    
        setSelectedInputIndex(null);
    };
    
    const updateConfig = (newConfig) => {
        const updatedConfigs = inputConfigs.map((config, index) =>
            index === selectedInputIndex ? newConfig : config
        );
        setInputConfigs(updatedConfigs);
    };

    return (
        <div>
            <div style={{ padding: '20px', width: '70%' }}>
                <FormNameEdit formName={formName} setFormName={setFormName} formId={formId} />

                <DragDropList
                    inputConfigs={inputConfigs}
                    setInputConfigs={setInputConfigs}
                    selectInputForEditing={selectInputForEditing}
                    handleDeleteField={handleDeleteField}
                    showEnglishInputs={showEnglishInputs}
                />
                <button onClick={handleAddFieldClick}>Ajouter un champ</button>
            </div>

            <ConfigWindow
                show={showConfigWindow}
                onSave={onSave}
                selectedConfig={inputConfigs[selectedInputIndex]}
                updateConfig={updateConfig}
                showEnglishInputs={showEnglishInputs}
                setShowEnglishInputs={setShowEnglishInputs}
            />
            
            <InputTypeSelectionModal showModal={showModal} setShowModal={setShowModal} onTypeSelect={handleTypeSelection} />
        </div>
    );
}

export default EditForm;
