// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
  }
  
  .modal-content {
    background-color: white;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid black;
    width: 50%;
    text-align: center;
  }
  
  .close-button {
    color: black;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Hint.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,QAAQ;IACR,UAAU;EACZ;;EAEA;IACE,uBAAuB;IACvB,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,UAAU;IACV,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,iBAAiB;EACnB","sourcesContent":[".modal {\n    position: fixed;\n    top: 0;\n    right: 0;\n    width: 50%;\n  }\n  \n  .modal-content {\n    background-color: white;\n    margin: 15% auto;\n    padding: 20px;\n    border: 1px solid black;\n    width: 50%;\n    text-align: center;\n  }\n  \n  .close-button {\n    color: black;\n    float: right;\n    font-size: 28px;\n    font-weight: bold;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
