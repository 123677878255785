import React from 'react';
import Email from './form/input/Email';
import Input from './form/input/Input';
import Textarea from './form/input/Textarea';
import Number from './form/input/Number';
import Date from './form/input/Date';
import Phone from './form/input/Phone';
import RadioButtonList from './form/input/RadioButtonList';
import Document from './form/input/Document';
import CheckboxList from './form/input/CheckboxList';
import Dropdown from './form/input/Dropdown';
import Don from './form/input/Don';

function DynamicInput({ type, ...props }) {
    if (type === 'email') {
        return <Email {...props} />;
    } else if (type === 'number') {
        return <Number {...props} />;
    } else if (type === 'date') {
        return <Date {...props} />;
    } else if (type === 'tel') {
        return <Phone {...props} />;
    } else if (type === 'textarea') {
        return <Textarea {...props} />;
    } else if (type === 'dropdown') {
        return <Dropdown {...props} />;
    } else if (type === 'checkbox-list') {
        return <CheckboxList {...props} selected={props.value} />;
    } else if (type === 'radio-button-list') {
        return <RadioButtonList {...props} />;
    } else if (type === 'document') {
        return <Document {...props} />;
    } else if (type === 'don') {
        return <Don {...props} />;
    } else {
        return <Input {...props} />;
    }
}

export default DynamicInput;
