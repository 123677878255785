import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import Hint from "../../Hint"
import '../../Hint.css'
function Phone({label, id, value, onChange, helper, layout, required}) {
    const handlePhoneChange = (phoneNumber) => {
        onChange({ target: { name: id, value: phoneNumber } });
    };
    const layoutClass = layout === 2 ? 'half-width' : 'full-width';
    return (
        <div className={layoutClass}>
            <label>{label}{required && <span style={{color: 'red'}}> *</span>}: </label>
            {helper && <Hint helperText={helper} />}
            <PhoneInput
                defaultCountry='CA'
                placeholder="Enter phone number"
                value={value}
                onChange={handlePhoneChange}
            />
        </div>
    )
}
export default Phone