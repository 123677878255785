import Hint from "../../Hint"
import '../../Hint.css'
function Dropdown({label, id, value, name, onChange, options, helper, layout, required}) {
    const layoutClass = layout === 2 ? 'half-width' : 'full-width';
    return (
        <div className={layoutClass}>
            <label>{label}{required && <span style={{color: 'red'}}> *</span>}: </label>
            {helper && <Hint helperText={helper} />}
            <select id={id} value={value} name={name} onChange={onChange}>
                {options.map((option) => (
                    <option key={option} value={option}>{option}</option>
                ))}
            </select>
      </div>
    )
}
export default Dropdown