import Hint from "../../Hint"
import '../../Hint.css'
function Document({label, id, onChange, layout, helper, required}) {
  const layoutClass = layout === 2 ? 'half-width' : 'full-width';
    return (
        <div className={layoutClass}>
          <label>{label}{required && <span style={{color: 'red'}}> *</span>}: </label>
          {helper && <Hint helperText={helper} />}
          <input type='file' name={id} onChange={onChange} accept="image/png,image/jpeg,text/plain,.pdf,.docx"/>
        </div>
    )
}
export default Document