import React from 'react';

const FormNameEdit = ({ formName, setFormName, formId }) => {
  const [showEditNameModal, setShowEditNameModal] = React.useState(false);

  const handleSaveName = (e) => {
    e.preventDefault();
    const newName = e.target.formName.value;

    localStorage.setItem(`formName-${formId}`, newName);

    let forms = JSON.parse(localStorage.getItem('forms') || '[]');
    const formIndex = forms.findIndex(form => form.id.toString() === formId);
    if (formIndex > -1) {
      forms[formIndex].name = newName;
      localStorage.setItem('forms', JSON.stringify(forms));
    }

    setShowEditNameModal(false);
    setFormName(newName);
  };

  return (
    <div>
      <h1>
        {formName} <button onClick={() => setShowEditNameModal(true)}>Change Name</button>
      </h1>
      {showEditNameModal && (
        <div style={{ position: 'absolute', top: '20%', left: '30%', background: 'white', padding: '20px', border: '1px solid #ccc', zIndex: 100 }}>
          <form onSubmit={handleSaveName}>
            <label>
              Form Name:
              <input name="formName" type="text" defaultValue={formName} />
            </label>
            <button type="submit">Save</button>
            <button type="button" onClick={() => setShowEditNameModal(false)}>Cancel</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default FormNameEdit;
