import Hint from "../../Hint"
import '../../Hint.css'
function RadioButtonList({label, value, options, onChange, id, helper, layout, required}) {
    const layoutClass = layout === 2 ? 'half-width' : 'full-width';
    return (
        <div className={layoutClass}>
            <div><label>{label}{required && <span style={{color: 'red'}}> *</span>}:</label></div>
            {options.map((option) => (
                <label key={option}>
                    <input type="radio" id={option} name={id} value={option} checked={value === option} onChange={onChange} />
                    <label>{option}</label>
                </label>
            ))}
        </div>
      );
}
export default RadioButtonList