import Hint from "../../Hint"
import '../../Hint.css'
function Input({label, id, value, name, onChange, placeholder, helper, layout, required}) {
    const layoutClass = layout === 2 ? 'half-width' : 'full-width';
    return (
        <div className={layoutClass}>
            <label>{label}{required && <span style={{color: 'red'}}> *</span>}: </label>
            {helper && <Hint helperText={helper} />}
            <input type="text" id={id} value={value} name={name} onChange={onChange} placeholder={placeholder}/>
        </div>
    )
}
export default Input