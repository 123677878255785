import React, {useState} from 'react';
import Dropdown from './form/input/Dropdown';
import Input from './form/input/Input';
import Date from './form/input/Date';
import Number from './form/input/Number';
import Email from './form/input/Email';
import Textarea from './form/input/Textarea';

const ConfigWindow = ({show, onSave, selectedConfig, updateConfig,showEnglishInputs, setShowEnglishInputs, }) => {
    const [showHelperInput, setShowHelperInput] = useState(false);

    if (!show || !selectedConfig) {
        return null;
    }

    const styleOptionsMapping = {
        "Aucun": "",
        "Coins carrés": "class-1",
        "Coins arrondis": "class-2",
    };
    
    const currentStyleLabel = Object.keys(styleOptionsMapping).find(key => styleOptionsMapping[key] === selectedConfig.className) || "Aucun";


    const handleChange = (event) => {
        const { name, value } = event.target;
        let updatedConfig = { ...selectedConfig };
    
        if (name === "className") {
            updatedConfig.className = styleOptionsMapping[value];
        } else if (name === "required" || name === "half" || name === "showCheckbox" || name === "showPeriodique") {
            updatedConfig[name] = value === "Oui";
        } else if (showEnglishInputs && (name === "label" || name === "placeholder" || name === "title")) {
            const nameEn = `${name}En`;
            updatedConfig[nameEn] = value;
        } else {
            updatedConfig[name] = value;
        }
    
        updateConfig(updatedConfig);
    };    


    const handleOptionChange = (index, languageKey, event) => {
        const newOptions = [...selectedConfig.options];
        newOptions[index][languageKey] = event.target.value;
        updateConfig({ ...selectedConfig, options: newOptions });
    };

    const addOption = () => {
        const newOption = { valueFr: '', valueEn: '' };
        const newOptions = [...selectedConfig.options, newOption];
        updateConfig({ ...selectedConfig, options: newOptions });
    };

    const removeOption = (index) => {
        const newOptions = selectedConfig.options.filter((_, idx) => idx !== index);
        updateConfig({ ...selectedConfig, options: newOptions });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave();
    };

    const handleAddHelper = () => {
        setShowHelperInput(true);
    };
    
    const handleRemoveHelper = () => {
        const updatedConfig = { ...selectedConfig, helper: '' };
        updateConfig(updatedConfig);
        setShowHelperInput(false);
    };    

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            right: 0,
            width: '30%',
            height: '100%',
            backgroundColor: 'white',
            padding: '20px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            boxSizing: 'border-box',
            zIndex: 1050,
            overflowY: 'auto',
            borderLeft: '1px solid #eee',
        }}>
            <form onSubmit={handleSubmit}>
                <h2>{`Modifier le champ #${selectedConfig.fieldNumber}`}</h2>
                <div>
                    <Dropdown
                        label={"Langue d'édition"}
                        value={showEnglishInputs ? "Anglais" : "Francais"}
                        onChange={(e) => setShowEnglishInputs(e.target.value === "Anglais")}
                        options={["Francais", "Anglais"]}
                    />
                </div>
                <div>
                    <Input
                        label={"Énoncé"}
                        name="label"
                        value={showEnglishInputs ? selectedConfig.labelEn : selectedConfig.label}
                        onChange={handleChange}
                    />
                </div>
                {(selectedConfig.type === 'text' || selectedConfig.type === 'textarea' || selectedConfig.type === 'number' || selectedConfig.type === 'email' || selectedConfig.type === 'password') && (
                    <Input
                        label={"Placeholder"}
                        name={showEnglishInputs ? "placeholderEn" : "placeholder"}
                        value={showEnglishInputs ? selectedConfig.placeholderEn : selectedConfig.placeholder}
                        onChange={handleChange}
                    />
                )}
                {selectedConfig.type === 'don' && (
                    <div>
                        <Input
                        label={"Titre"}
                        name={showEnglishInputs ? "titleEn" : "title"}
                        value={showEnglishInputs && selectedConfig.titleEn ? selectedConfig.titleEn : selectedConfig.title}
                        onChange={handleChange}
                    />
                    </div>
                )}


                {!['dropdown', 'radio', 'checkbox', 'don', 'phone', 'document', 'password'].includes(selectedConfig.type) && (
                    <div>
                        {selectedConfig.type === 'date' ? (
                            <Date
                                label={"Valeur par défaut"}
                                name="defaultValue"
                                value={selectedConfig.defaultValue || ''}
                                onChange={handleChange}
                            />
                        ) : selectedConfig.type === 'email' ? (
                            <Email
                                label={"Valeur par défaut"}
                                placeholder={"exemple@exemple.com"}
                                name={showEnglishInputs ? "defaultValueEn" : "defaultValue"}
                                value={showEnglishInputs ? (selectedConfig.defaultValueEn || '') : (selectedConfig.defaultValue || '')}
                                onChange={handleChange}
                            />
                        ) : selectedConfig.type === 'number' ? (
                            <Number
                                label={"Valeur par défaut"}
                                name={showEnglishInputs ? "defaultValueEn" : "defaultValue"}
                                value={showEnglishInputs ? (selectedConfig.defaultValueEn || '') : (selectedConfig.defaultValue || '')}
                                onChange={handleChange}
                            />
                        ) : (
                            <Input
                                label={"Valeur par défaut"}
                                name={showEnglishInputs ? "defaultValueEn" : "defaultValue"}
                                value={showEnglishInputs ? (selectedConfig.defaultValueEn || '') : (selectedConfig.defaultValue || '')}
                                onChange={handleChange}
                            />
                        )}
                    </div>
                )}

                {['dropdown', 'radio', 'don'].includes(selectedConfig.type) && (
                    <Dropdown
                        label={"Valeur par défaut"}
                        name="defaultValue"
                        value={selectedConfig.defaultValue || ''}
                        onChange={handleChange}
                        options={selectedConfig.options.map(option => 
                            option.value
                        )}
                    />
                )}

                {selectedConfig.type === 'date' && (
                    <>
                        <div>
                            <Date
                            label={"Minimum Date"}
                                name="minDate"
                                value={selectedConfig.minDate || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <Date
                                label={"Maximum Date"}
                                name="maxDate"
                                value={selectedConfig.maxDate || ''}
                                onChange={handleChange}
                            />
                        </div>
                    </>
                )}
                {['dropdown', 'radio', 'checkbox', 'don'].includes(selectedConfig.type) && (
                    <>
                        {selectedConfig.options.map((option, index) => (
                            <div key={index}>
                                {selectedConfig.type === 'don' ? (
                                    <Number
                                        label={`Option ${index + 1}`}
                                        value={option.value}
                                        onChange={(e) => handleOptionChange(index, 'value', e)}
                                    />
                                ) : (
                                    <Input
                                        label={`Option ${index + 1}`}
                                        value={showEnglishInputs ? option.valueEn : option.valueFr}
                                        onChange={(e) => handleOptionChange(index, showEnglishInputs ? 'valueEn' : 'valueFr', e)}
                                    />
                                )}
                                <button type="button" onClick={() => removeOption(index)}>Remove</button>
                            </div>
                        ))}
                        <button type="button" onClick={addOption}>Add Option</button>
                    </>
                )}

                {selectedConfig.type !== 'don' && (
                    <>
                        <div>
                            <Dropdown
                                label={"Champ obligatoire"}
                                name="required"
                                value={selectedConfig.required ? "Oui" : "Non"}
                                onChange={handleChange}
                                options={["Oui", "Non"]}
                            />
                        </div>
                        <div>
                            <Dropdown
                                label={"Demi-champ"}
                                name="half"
                                value={selectedConfig.half ? "Oui" : "Non"}
                                onChange={handleChange}
                                options={["Oui", "Non"]}
                            />
                        </div>

                        <div>
                            <Dropdown
                                label="Style"
                                value={currentStyleLabel}
                                name="className"
                                onChange={handleChange}
                                options={Object.keys(styleOptionsMapping)}
                            />
                        </div>
                    </>
                )}

                <div>
                    {!showHelperInput ? (
                        <button type="button" onClick={handleAddHelper}>Add Helper</button>
                    ) : (
                        <>
                            <Textarea
                                label={"Helper"}
                                name={showEnglishInputs ? "helperEn" : "helper"}
                                value={showEnglishInputs ? selectedConfig.helperEn : selectedConfig.helper}
                                onChange={handleChange}
                            />
                            <button type="button" onClick={handleRemoveHelper}>Remove Helper</button>
                        </>
                    )}
                </div>

                {selectedConfig.type === 'don' && (
                    <>
                        <div>
                            <Number
                                label="Valeur Minimale"
                                name="minValue"
                                value={selectedConfig.minValue || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <Number
                                label="Valeur Maximale"
                                name="maxValue"
                                value={selectedConfig.maxValue || ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <Dropdown
                                label="Afficher checkbox"
                                name="showCheckbox"
                                value={selectedConfig.showCheckbox ? "Oui" : "Non"}
                                onChange={handleChange}
                                options={["Oui", "Non"]}
                            />
                        </div>
                        <div>
                            <Dropdown
                                label="Afficher Periodique"
                                name="showPeriodique"
                                value={selectedConfig.showPeriodique ? "Oui" : "Non"}
                                onChange={handleChange}
                                options={["Oui", "Non"]}
                            />
                        </div>
                    </>
                )}

            </form>
            <button onClick={onSave} style={{ marginTop: '20px', display: 'block', width: '100%' }}>Save</button>
        </div>
    );
};

export default ConfigWindow;
